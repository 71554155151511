<template>
  <div>
    <CRow>
      <CCol sm="12">
        <PaymentsTable
          :items="payments"
          hover
          striped
          border
          small
          fixed
          caption="Recepción de Pagos"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import PaymentsTable from '../components/payments/PaymentsTable.vue'
import ws from '../services/payments';
import store from '../store'

export default {
  name: 'Payments',
  components: { PaymentsTable },
  data: function () {
		return {
            payments: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get("active"); 

    if(response.type == "success"){
      this.payments = response.data;
    }
    this.loaded();
  },
  methods: {
    async refreshItems (tab,filters = {}){
      this.loading();
      let response = await ws.get(tab, filters); 
      
      if(response.type == "success"){
        this.payments = response.data;
      }

      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
